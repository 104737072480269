"use client";

import { throttle } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";

// We want this to be quite snappy, and the calculations are fast per call.
const DEFAULT_THROTTLE_DURATION = 10;

const MIN_PX_PER_SECOND = 100;

type UseWindowScrollOptions = {
  /**
   * The minimum distance that the page should travel per second to be consider "scrolling".
   *
   * Example A:
   *   minSpeed: 100 pixels per second
   *   distance: 200 pixels
   *   time: 4 seconds
   *   speed: 50 pixels per second
   *   isScrolling: false
   *
   * Example B:
   *   minSpeed: 100 pixels per second
   *   distance: 500 pixels
   *   time: 1 seconds
   *   speed: 500 pixels per second
   *   isScrolling: true
   */
  minSpeed?: number;

  /**
   * The threshold for the scroll to be "at end" of screen, in pixels.
   *
   * Example:
   *   endThreshold: 100 pixels
   *   maxScroll: 800 pixels
   *   scrollY: 750 pixels
   *   isAtEnd: true
   */
  endThreshold?: number;

  /**
   * The threshold for the scroll to be "at start" of screen, in pixels.
   *
   * Example:
   *   startThreshold: 100 pixels
   *   scrollY: 50 pixels
   *   isAtStart: true
   */
  startThreshold?: number;

  /**
   * The minimum milliseconds between each call to the scroll listener, in ms.
   * Update this if you are getting rendering performance issues.
   */
  throttleDuration?: number;
};

export type ScrollDirection = "down" | "up" | null;

/**
 * Hook to see if the user is scrolling and in what direction,
 * as determined by a minimum scrolling speed.
 *
 * The minimum speed ensures that we get no "false" scrolls from
 * touch and click interactions.
 */
export function useWindowScroll({
  minSpeed = MIN_PX_PER_SECOND,
  endThreshold = 0,
  startThreshold = 0,
  throttleDuration = DEFAULT_THROTTLE_DURATION,
}: UseWindowScrollOptions = {}) {
  const prevScrollPosition = useRef(0);
  const maxScroll = useRef(0);
  const lastDirection = useRef<ScrollDirection>(null);
  const lastCall = useRef(Date.now());

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrollActive, setIsScrollActive] = useState(false);

  const handleScroll = () => {
    prevScrollPosition.current = scrollPosition;
    maxScroll.current = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
    );

    setIsScrollActive(true);
    setScrollPosition(window.scrollY);
  };

  const handleScrollEnd = () => {
    prevScrollPosition.current = scrollPosition;

    maxScroll.current = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
    );

    setScrollPosition(window.scrollY);
    setIsScrollActive(false);
  };

  useEffect(() => {
    const throttledScrollHandler = throttle(handleScroll, throttleDuration, {
      leading: true,
    });

    window.addEventListener("scroll", throttledScrollHandler);
    window.addEventListener("scrollend", handleScrollEnd);

    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
      window.removeEventListener("scrollEnd", handleScrollEnd);
    };
  }, [handleScroll, handleScrollEnd, throttleDuration]);

  /**
   * Save the difference in ms since the last time this hook was evaluated,
   * so we can use that as basis for speed calculations.
   * (might not be 100% precise, but good enough)
   */
  const timeDiffMs = Date.now() - lastCall.current;
  lastCall.current = Date.now();

  const isAtStart = scrollPosition <= startThreshold;
  const isAtEnd = maxScroll.current - scrollPosition >= endThreshold;

  let direction: "down" | "up" | null = null;

  if (isScrollActive) {
    // Measure the difference between the last recorded value
    const pxDiff = scrollPosition - prevScrollPosition.current;

    /**
     * The amount of pixels moved per ms.
     *
     * Example:
     *   400px / 50ms = 8 px per ms.
     */
    const pxPerMs = Math.abs(pxDiff) / timeDiffMs;
    const minSpeedPerMs = minSpeed / 1000;

    if (pxPerMs > minSpeedPerMs) {
      direction = pxDiff >= 0 ? "down" : "up";

      /**
       * We only want to save the direction when we are actually scrolling,
       * otherwise the last direction would be null when not scrolling.
       */
      lastDirection.current = direction;
    }
  }

  return useMemo(
    () => ({
      /**
       * If the user is scrolling, and the speed is higher than the minimum speed.
       */
      isScrolling: Boolean(direction),

      /**
       * The current direction the user is scrolling,
       * null if not scrolling or not over the minimum speed.
       */
      direction,

      /**
       * The last known scroll direction, even if not scrolling right now.
       */
      lastDirection: direction ?? lastDirection.current,

      /**
       * If the scroll position is at the start of the window.
       * Is set regardless of "isScrolling" and "direction".
       */
      isAtStart,

      /**
       * If the scroll position is at the end of the window.
       * Is set regardless of "isScrolling" and "direction".
       */
      isAtEnd,
    }),
    [direction, isAtStart, isAtEnd],
  );
}
